@tailwind base;
@tailwind components;
@tailwind utilities;

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.dropdownContent {
	width: var(--radix-popper-anchor-width);
}

/* Buttons */
.button {
	@apply box-border inline-flex justify-center w-max py-2 px-6 no-underline whitespace-nowrap cursor-pointer border-0 rounded-full transition-colors;
}

.button--primary {
	@apply bg-primary-50 text-on-primary-50 hover:bg-primary-70 hover:text-on-primary-70;
}

.button--secondary {
	@apply bg-secondary-50 text-on-secondary-50 hover:bg-on-secondary-30 hover:text-on-secondary-50;
}

/* Typography */
h1, h2, h3, h4 {
	@apply mt-0 mb-4 mx-0;
}

h1, .h1 {
	@apply text-[2rem] lg:text-[2.6875rem] xl:text-[3.375rem] font-bold not-italic leading-[1.2];
}

h2, .h2 {
	@apply text-[1.625rem] lg:text-[1.75rem] xl:text-[2rem] font-bold not-italic leading-[1.2];
}

h3, .h3 {
	@apply text-[1.375rem] xl:text-[1.5625rem] font-bold not-italic leading-[1.2];
}

h4, .h4 {
	@apply text-[1.125rem] xl:text-[1.25rem] font-bold not-italic leading-[1.2];
}

.display-header {
	@apply text-[2.45rem] xl:text-[4.75rem] font-normal not-italic leading-[1.2] mt-0 mb-6 mx-0 xl:mb-8 tracking-text;
}

/* Body */
.body {
	@apply leading-[1.45] font-normal last:mb-0;
}
.body:is(p) {
	@apply mx-0 mt-0 mb-4 last:mb-0;
}
.body--large {
	@apply text-[1.375rem] xl:text-[1.5625rem] tracking-text;
}
.body--medium {
	@apply text-[1.125rem] xl:text-[1.25rem] tracking-text;
}
.body--small {
	@apply text-[0.9375rem] xl:text-base font-text tracking-text;
}
.body--xsmall {
	@apply text-xs md:text-xs xl:text-sm font-text tracking-text;
}

li > p {
	@apply inline
}

/* Inlinelink */

@layer components {
	.inline-link {
		@apply inline px-1 py-0 my-0 mx-[-4px] text-on-surface bg-repeat-x underline;
		background-image: linear-gradient(to right, #CDF4C6 100%, #fff 0);
		background-repeat: repeat-x;
		background-position: 0 80%;
		background-size: 100% 33%;
		transition: background-size .1s;
	}

	.inline-link:hover, .inline-link:focus, .inline-link:active {
		background-size: 100% 80%;
	}

}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
	background-color: #CAC6C1!important;
}

#CybotCookiebotDialog form input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider,
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
	background-color: #005E49!important;
}

/* Listing Web */
.input-styles {
  @apply  h-12 border-x border-y p-2 rounded-lg focus:border-primary-50 focus:outline-none focus:ring-0
}

.map-container {
  width: 100%;
  height: 100%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

